import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  CircularProgress
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext'; // ajusta conforme a estrutura do seu projeto

const API_URL = "http://108.165.179.193:8000";

const PrevisaoProducaoPage = () => {
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [previsao, setPrevisao] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useContext(AuthContext); // token armazenado no contexto

  const buscarPrevisao = async () => {
    try {
      setLoading(true);
      const dataFormatada = format(dataSelecionada, "yyyy-MM-dd");

      const response = await axios.get(`${API_URL}/producao/prever`, {
        params: { data: dataFormatada },
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      });

      setPrevisao(response.data);
    } catch (err) {
      console.error('Erro ao buscar previsão:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h5" fontWeight={600} mb={2}>
        📅 Previsão de Produção
      </Typography>

      <Paper elevation={2} sx={{ p: 2, mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <DatePicker
                label="Selecionar Data"
                value={dataSelecionada}
                onChange={(newValue) => setDataSelecionada(newValue)}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                onClick={buscarPrevisao}
                disabled={loading}
                sx={{ height: '100%' }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
              </Button>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>

      {previsao && (
        <Grid container spacing={2}>
          {Object.entries(previsao).map(([prato, quantidade]) => (
            <Grid item xs={12} sm={6} md={4} key={prato}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="subtitle1" fontWeight={500}>
                  {prato}
                </Typography>
                <Typography variant="h6">
                  {quantidade} unidades
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {!previsao && !loading && (
        <Typography variant="body2" mt={2}>
          Selecione uma data e clique em "Buscar" para ver a previsão de produção.
        </Typography>
      )}
    </Box>
  );
};

export default PrevisaoProducaoPage;
