
// DeliveryPage.js (completo com filtro de métricas zeradas e proteção contra NaN)

import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import {
  ChartContainer,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  ChartsReferenceLine,
  ChartsText
} from "@mui/x-charts";

import { AuthContext } from "../../contexts/AuthContext";
import MenuDrawer from "./MenuDrawer";

const API_URL = "http://108.165.179.193:8000";

const metrics = [
  { key: "tempo_entrega_p10", label: "TEMPO TOTAL DE ENTREGA P10" },
  { key: "tempo_entrega_ifood", label: "TEMPO TOTAL DE ENTREGA IFOOD" },
  { key: "tc_retirada", label: "TC RETIRADA" },
  { key: "media_tempo_kds_montagem", label: "MÉDIA TEMPO KDS MONTAGEM" },
  { key: "media_tempo_kds_expedicao", label: "MÉDIA TEMPO KDS EXPEDIÇÃO" },
  { key: "kds_producao_media_preparo", label: "KDS PRODUÇÃO - MÉDIA DE PREPARO" },
  { key: "kds_producao_media_inicio", label: "KDS PRODUÇÃO - MÉDIA DE INICIO" },
  { key: "kds_producao_media_atraso", label: "KDS PRODUÇÃO - MÉDIA DE ATRASO" },
  { key: "tempo_producao", label: "TEMPO DE PRODUÇÃO" },
  { key: "tempo_pronto_ate_chegar", label: "TEMPO DE PRONTO ATÉ CHEGAR" },
  { key: "do_pronto_ate_sair", label: "TEMPO ENTREGADOR CHEGAR NA LOJA" },
  { key: "atrasos_hoje", label: "QUANTOS ATRASOS HOJE?", prefix: "sum_" },
  { key: "double_check", label: "QUANTOS DOUBLE CHECK?", prefix: "sum_" }
];

const timeMetrics = [
  "tempo_entrega_p10",
  "tempo_entrega_ifood",
  "media_tempo_kds_montagem",
  "media_tempo_kds_expedicao",
  "kds_producao_media_preparo",
  "kds_producao_media_inicio",
  "kds_producao_media_atraso",
  "tempo_producao",
  "tempo_pronto_ate_chegar",
  "do_pronto_ate_sair"
];

const metricGoals = {
  tempo_entrega_p10: 1646,              // 00:27:26 → 27*60 + 26 = 1646
  tempo_entrega_ifood: 1990,            // 00:33:10 → 33*60 + 10 = 1990
  media_tempo_kds_montagem: 336,       // 00:05:36 → 5*60 + 36 = 336
  media_tempo_kds_expedicao: 403,      // 00:06:43 → 6*60 + 43 = 403
  kds_producao_media_preparo: 465,     // 00:07:45 → 7*60 + 45 = 465
  kds_producao_media_inicio: 83,       // 00:01:23 → 1*60 + 23 = 83
  kds_producao_media_atraso: 186,      // 00:03:06 → 3*60 + 6 = 186
  tempo_producao: 609,                 // 00:10:09 → 10*60 + 9 = 609
  atrasos_hoje: 13,                    // 13
  double_check: 3                      // Meta numérica simples
};


const secondsToTimeString = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const pad = (n) => String(n).padStart(2, "0");
  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export default function DeliveryPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelow900 = useMediaQuery(theme.breakpoints.down("md"));
  const isBelow1800 = useMediaQuery("(max-width:1799px)");
  const drawerVariant = isBelow1800 ? "temporary" : "persistent";

  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [roleId, setRoleId] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [dateRanges1, setDateRanges1] = useState([null, null]);
  const [dateRanges2, setDateRanges2] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
    try {
      const payload = JSON.parse(atob(auth.token.split(".")[1]));
      setRoleId(payload.role_id);
    } catch (error) {
      console.error("Erro ao decodificar token:", error);
      logout();
    }
  }, [auth.token, navigate, logout]);

  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);
  const formatMonth = (date) => (date ? format(new Date(date), "LLLL", { locale: ptBR }) : null);

  const label1Month = dateRanges1[0] ? formatMonth(dateRanges1[0]) : "Intervalo 1";
  const label2Month = dateRanges2[0] ? formatMonth(dateRanges2[0]) : "Intervalo 2";
  const sameMonth = label1Month === label2Month && label1Month !== "Intervalo 1" && label2Month !== "Intervalo 2";
  const label1Text = sameMonth ? `${label1Month} 1` : label1Month;
  const label2Text = sameMonth ? `${label2Month} 2` : label2Month;

  const handleCompareDates = async () => {
    if (!dateRanges1[0] || !dateRanges1[1] || !dateRanges2[0] || !dateRanges2[1]) {
      console.error("⚠️ Selecione todas as datas antes de comparar.");
      return;
    }
    try {
      const token = auth?.token;
      const payload = {
        start_date_1: formatDate(dateRanges1[0]),
        end_date_1: formatDate(dateRanges1[1]),
        start_date_2: formatDate(dateRanges2[0]),
        end_date_2: formatDate(dateRanges2[1])
      };
      const response = await axios.post(
        `${API_URL}/delivery/compare-dates-delivery`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
      setComparisonData(response.data);
    } catch (error) {
      console.error("❌ Erro ao comparar datas (Delivery):", error);
    }
  };

  const gridProps = !isBelow1800 ? { xs: 12, sm: 6, md: 3 } : { xs: 12, sm: 6, md: 4 };

  let chartWidth = 250;
  let chartHeight = 200;
  if (!isBelow900 && isBelow1800) {
    chartWidth = 300;
    chartHeight = 220;
  } else if (!isBelow1800) {
    chartWidth = 350;
    chartHeight = 250;
  }

  const renderChart = (metric, data1, data2) => {
    const isTempo = timeMetrics.includes(metric.key);
    const dataPrefix = metric.prefix ? metric.prefix : "average_";

    let raw1 = data1[dataPrefix + metric.key];
    let raw2 = data2[dataPrefix + metric.key];

    const isValid1 = typeof raw1 === "number" && raw1 > 0;
    const isValid2 = typeof raw2 === "number" && raw2 > 0;

    if (!isValid1 && !isValid2) {
      return null;
    }

    const value1 = isValid1 ? raw1 : 0;
    const value2 = isValid2 ? raw2 : 0;

    const formatted1 = isTempo ? secondsToTimeString(value1) : value1.toFixed(1);
    const formatted2 = isTempo ? secondsToTimeString(value2) : value2.toFixed(1);

    const metaValue = metricGoals[metric.key];
    const showMeta = metaValue !== undefined;
    const maxValue = Math.max(value1, value2, showMeta ? metaValue : 0, 1);

    const barTop1 = chartHeight * (1 - (value1 / maxValue));
    const centerY1 = (barTop1 + chartHeight) / 2;

    const barTop2 = chartHeight * (1 - (value2 / maxValue));
    const centerY2 = (barTop2 + chartHeight) / 2;

    return (
      <Box>
        <Typography variant="subtitle2" align="center" sx={{ mb: 1 }}>
          {metric.label}
        </Typography>
        <ChartContainer
          width={chartWidth}
          height={chartHeight + 20}
          xAxis={[{ id: "x", scaleType: "band", data: [label1Text, label2Text] }]}
          yAxis={[{ id: "y", scaleType: "linear" }]}
          series={[
            {
              type: "bar",
              xAxisKey: "x",
              yAxisKey: "y",
              data: [value1, value2],
              label: metric.label
            }
          ]}
        >
          <BarPlot />
          <ChartsXAxis />
          <ChartsYAxis />
          {showMeta && (
            <ChartsReferenceLine
              y={metaValue}
              label={`Meta: ${isTempo ? secondsToTimeString(metaValue) : metaValue}`}
              lineStyle={{ stroke: "#FF0000", strokeDasharray: "6 4" }}
            />
          )}
          {isValid1 && (
            <ChartsText
              x={chartWidth * 0.25 + 25}
              y={centerY1}
              text={formatted1}
              style={{ fill: "#000", fontSize: 14, textAnchor: "middle", dominantBaseline: "central" }}
            />
          )}
          {isValid2 && (
            <ChartsText
              x={chartWidth * 0.75 - 25}
              y={centerY2}
              text={formatted2}
              style={{ fill: "#000", fontSize: 14, textAnchor: "middle", dominantBaseline: "central" }}
            />
          )}
        </ChartContainer>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: "#fff", color: theme.palette.text.primary }}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => setDrawerOpen(!drawerOpen)} edge="start" sx={{ mr: 2 }}>
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <MenuDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggle={() => setDrawerOpen(!drawerOpen)}
        roleId={roleId}
        variant={drawerVariant}
      />

      <Box component="main" sx={{ flexGrow: 1, minHeight: "100vh", mt: 8, p: 3, backgroundColor: "#eef2f6", borderRadius: 3 }}>
        <Box sx={{ backgroundColor: "#fff", borderRadius: 2, p: 3, mb: 3, boxShadow: 1 }}>
          <Typography variant="h5" gutterBottom>📊 Delivery - Dashboard</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker label="Data Inicial 1" value={dateRanges1[0]} onChange={(newValue) => setDateRanges1([newValue, dateRanges1[1]])} />
                <DatePicker label="Data Final 1" value={dateRanges1[1]} onChange={(newValue) => setDateRanges1([dateRanges1[0], newValue])} />
              </Box>
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <DatePicker label="Data Inicial 2" value={dateRanges2[0]} onChange={(newValue) => setDateRanges2([newValue, dateRanges2[1]])} />
                <DatePicker label="Data Final 2" value={dateRanges2[1]} onChange={(newValue) => setDateRanges2([dateRanges2[0], newValue])} />
              </Box>
              <Button variant="contained" color="primary" onClick={handleCompareDates}>Comparar Intervalos de Datas</Button>
            </Box>
          </LocalizationProvider>
        </Box>

        {comparisonData ? (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Médias dos Indicadores de Delivery</Typography>
            <Typography variant="body1">{label1Text}</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>{label2Text}</Typography>
            <Grid container spacing={2}>
              {metrics.map((metric) => {
                const chart = renderChart(metric, comparisonData.interval_1, comparisonData.interval_2);
                return chart ? (
                  <Grid item {...gridProps} key={metric.key}>
                    <Card sx={{ backgroundColor: "#fff", mb: 2, p: 2 }}>
                      <CardContent>
                        {chart}
                        <Typography variant="body2" sx={{ color: "#000000", textAlign: "center", mt: 1 }}>
                          Diferença: {comparisonData["percentage_" + metric.key]?.toFixed(1) || "0"}%
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : null;
              })}
            </Grid>
          </Box>
        ) : (
          <Typography variant="body1">Nada a exibir</Typography>
        )}
      </Box>
    </Box>
  );
}
