import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme/theme';
import { AuthContext } from './contexts/AuthContext';

// Importação das Páginas
import LoginPage from './components/pages/LoginPage';
import RegisterPage from './components/pages/RegisterPage';
import MainMenu from './components/pages/MainMenu';
import LojaIA from './components/pages/LojaIA';
import ProfilePage from './components/pages/ProfilePage';
import PrevisaoProducaoPage from './components/pages/PrevisaoProducaoPage';
import BIPage from './components/pages/BIPage';
import LandingPage from './components/pages/LandingPage';
import NotFoundPage from './components/pages/NotFoundPage';
import MarketingPage from './components/pages/MarketingPage';
import DeliveryPage from './components/pages/DeliveryPage';
import LojaPage from './components/pages/LojaPage';
import PratosPage from './components/pages/PratosPage';
import FaturamentoPage from './components/pages/faturamento.jsx';
import DrePage from './components/pages/DrePage';
import ProducaoPage from './components/pages/ProducaoPage';
import SaidaPage from './components/pages/saidapage.jsx';
import EntradaPage from './components/pages/EntradaPage';

// **Importe a nova página de Faltas**
import FaltasPage from './components/pages/FaltasPage'; // Ajuste o caminho conforme a sua estrutura

const ProtectedRoute = ({ children }) => {
  const { auth } = useContext(AuthContext);
  return auth?.token ? children : <Navigate to="/login" replace />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {/* Rota Inicial - Landing Page */}
        <Route path="/" element={<LandingPage />} />

        {/* Páginas Públicas */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />

        {/* Páginas Protegidas */}
        <Route path="/menu" element={<ProtectedRoute><MainMenu /></ProtectedRoute>} />
        <Route path="/LojaIA" element={<ProtectedRoute><LojaIA /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
        <Route path="/bipage" element={<ProtectedRoute><BIPage /></ProtectedRoute>} />
        <Route path="/previsao-producao" element={<ProtectedRoute><PrevisaoProducaoPage /></ProtectedRoute>} />

        {/* Novas Rotas */}
        <Route path="/marketing" element={<ProtectedRoute><MarketingPage /></ProtectedRoute>} />
        <Route path="/delivery" element={<ProtectedRoute><DeliveryPage /></ProtectedRoute>} />
        <Route path="/loja" element={<ProtectedRoute><LojaPage /></ProtectedRoute>} />
        <Route path="/pratos" element={<ProtectedRoute><PratosPage /></ProtectedRoute>} />
        <Route path="/faturamento" element={<ProtectedRoute><FaturamentoPage /></ProtectedRoute>} />
        <Route path="/dre" element={<ProtectedRoute><DrePage /></ProtectedRoute>} />
        <Route path="/producao" element={<ProtectedRoute><ProducaoPage /></ProtectedRoute>} />

        {/* Rotas para Entrada e Saída */}
        <Route path="/entrada" element={<ProtectedRoute><EntradaPage /></ProtectedRoute>} />
        <Route path="/saida" element={<ProtectedRoute><SaidaPage /></ProtectedRoute>} />

        {/* 
          Adicione aqui a nova rota de Faltas, 
          seguindo o mesmo padrão de rotas protegidas
        */}
        <Route path="/faltas" element={<ProtectedRoute><FaltasPage /></ProtectedRoute>} />

        {/* Rota de Página Não Encontrada */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
