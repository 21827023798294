import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CssBaseline,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import {
  ChartContainer,
  BarPlot,
  ChartsXAxis,
  ChartsYAxis,
  ChartsText
} from "@mui/x-charts";

import { AuthContext } from "../../contexts/AuthContext";
import MenuDrawer from "./MenuDrawer";

const API_URL = "http://108.165.179.193:8000";

// Lista de métricas (perguntas) para o relatório em lista
const metrics = [
  { key: "checklist_problems", label: "HOJE TEVE PROBLEMAS DE CHECKLIST?" },
  { key: "checklist_issue", label: "O que aconteceu que não foi feito o checklist?" },
  { key: "team_behavior", label: "COMO FOI O COMPORTAMENTO DA EQUIPE NO DIA DE HOJE?" },
  { key: "problem_details", label: "HOUVE ALGUM PROBLEMA COM: INFORMÁTICA, SISTEMA, TAA, KDS, IMPRESSORAS, SISTEMA APP'S - DESCREVER O QUE." },
  { key: "client_complaints", label: "HOUVE RECLAMAÇÕES DE CLIENTES? SE SIM, QUAIS?" },
  { key: "assistance_requested", label: "FOI NECESSÁRIO SOLICITAR AJUDA? (na montagem, atendimento, etc...)" },
  { key: "logistics_issues", label: "HOUVERAM PROBLEMAS COM A LOGÍSTICA E ENTREGADORES?" },
  { key: "entry_compliance", label: "ELES ESTÃO RESPEITANDO A ENTRADA SEM MOCHILA E CAPACETE? (Solicitar educadamente, caso não atendam)" },
  { key: "absence_info", label: "TEVE FALTA? NOME-MOTIVO" }
];

export default function FaltasChartPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  // Estado para intervalo de datas e dados
  const [dateRange, setDateRange] = useState([null, null]);
  const [comparisonData, setComparisonData] = useState(null);

  useEffect(() => {
    if (!auth?.token) {
      navigate("/");
      return;
    }
  }, [auth, navigate]);

  // Formatação de datas
  const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd") : null);
  const formatMonth = (date) =>
    date ? format(new Date(date), "LLLL", { locale: ptBR }) : null;

  const labelStart = dateRange[0] ? formatMonth(dateRange[0]) : "Data Inicial";
  const labelEnd = dateRange[1] ? formatMonth(dateRange[1]) : "Data Final";

  // Consulta a API
  const handleCompareDates = async () => {
    if (!dateRange[0] || !dateRange[1]) {
      console.error("Selecione a data inicial e a data final.");
      return;
    }
    try {
      const token = auth?.token;
      const payload = {
        start_date: formatDate(dateRange[0]),
        end_date: formatDate(dateRange[1])
      };
      const response = await axios.post(
        `${API_URL}/faltas/compare-dates-faltas`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );
      setComparisonData(response.data);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  // Agregação dos dados para o gráfico:
  // 1) Somamos os valores numéricos do campo "checklist_problems"
  // 2) Somamos os valores numéricos do campo "absence_info" (se houver)
  let totalChecklistProblems = 0;
  let totalAbsences = 0;

  if (comparisonData && comparisonData.records) {
    comparisonData.records.forEach((record) => {
      // checklist_problems já vem como número do backend
      totalChecklistProblems += Number(record.checklist_problems) || 0;

      // Se absence_info também tiver valores numéricos, somamos:
      if (record.absence_info && !record.absence_info.toLowerCase().includes("sem informação")) {
        const numericAbsence = parseFloat(record.absence_info);
        if (!isNaN(numericAbsence)) {
          totalAbsences += numericAbsence;
        }
      }
    });
  }

  // Ajuste de dimensões do gráfico
  let chartWidth = 300;
  let chartHeight = 250;
  if (isMobile) {
    chartWidth = 250;
    chartHeight = 200;
  }

  // Cálculo do valor máximo (para posicionar labels)
  const maxValue = Math.max(totalChecklistProblems, totalAbsences, 1);
  const barTop1 = chartHeight * (1 - totalChecklistProblems / maxValue);
  const centerY1 = (barTop1 + chartHeight) / 2;
  const barTop2 = chartHeight * (1 - totalAbsences / maxValue);
  const centerY2 = (barTop2 + chartHeight) / 2;

  return (
    <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
      <CssBaseline />
      {/* AppBar */}
      <AppBar position="fixed" sx={{ backgroundColor: "#fff", color: theme.palette.text.primary }}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => setDrawerOpen(!drawerOpen)}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon sx={{ fontSize: 20 }} />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" color="secondary" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Menu Lateral */}
      <MenuDrawer
        drawerOpen={drawerOpen}
        handleDrawerToggle={() => setDrawerOpen(!drawerOpen)}
      />

      {/* Área Principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          mt: 8,
          p: 3,
          backgroundColor: "#eef2f6",
          borderRadius: 3
        }}
      >
        {/* Seção do Gráfico no Topo */}
        <Box
          sx={{
            backgroundColor: "#fff",
            p: 3,
            borderRadius: 2,
            mb: 3,
            boxShadow: 1
          }}
        >
          <Typography variant="h6" gutterBottom>
            Resumo do Período
          </Typography>
          {comparisonData ? (
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <ChartContainer
                  width={chartWidth}
                  height={chartHeight + 20}
                  xAxis={[
                    { id: "x", scaleType: "band", data: ["Problemas de Checklist", "Faltas"] }
                  ]}
                  yAxis={[
                    { id: "y", scaleType: "linear", domain: [0, maxValue + 1] }
                  ]}
                  series={[
                    {
                      type: "bar",
                      xAxisKey: "x",
                      yAxisKey: "y",
                      data: [totalChecklistProblems, totalAbsences],
                      label: "Ocorrências"
                    }
                  ]}
                >
                  <BarPlot />
                  <ChartsXAxis />
                  <ChartsYAxis />
                  {/* Labels acima das barras */}
                  {totalChecklistProblems > 0 && (
                    <ChartsText
                      x={chartWidth * 0.25 + 25}
                      y={centerY1}
                      text={String(totalChecklistProblems)}
                      style={{
                        fill: "#000",
                        fontSize: 14,
                        textAnchor: "middle",
                        dominantBaseline: "central"
                      }}
                    />
                  )}
                  {totalAbsences > 0 && (
                    <ChartsText
                      x={chartWidth * 0.75 - 25}
                      y={centerY2}
                      text={String(totalAbsences)}
                      style={{
                        fill: "#000",
                        fontSize: 14,
                        textAnchor: "middle",
                        dominantBaseline: "central"
                      }}
                    />
                  )}
                </ChartContainer>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">Nenhum dado para exibir</Typography>
          )}
        </Box>

        {/* Filtros de Datas */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            p: 3,
            mb: 3,
            boxShadow: 1
          }}
        >
          <Typography variant="h5" gutterBottom>
            Filtros de Datas
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: "flex", gap: 2, mb: 3, alignItems: "center" }}>
              <DatePicker
                label="Data Inicial"
                value={dateRange[0]}
                onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
              />
              <DatePicker
                label="Data Final"
                value={dateRange[1]}
                onChange={(newValue) => setDateRange([dateRange[0], newValue])}
              />
              <Button variant="contained" color="primary" onClick={handleCompareDates}>
                Buscar Dados
              </Button>
            </Box>
          </LocalizationProvider>
          <Typography variant="subtitle1">
            Período: {labelStart} a {labelEnd}
          </Typography>
        </Box>

        {/* Relatório em Lista */}
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            p: 3,
            boxShadow: 1,
            mt: 3
          }}
        >
          <Typography variant="h6" gutterBottom>
            Registros para o período: {labelStart} a {labelEnd}
          </Typography>
          {comparisonData && comparisonData.records && comparisonData.records.length > 0 ? (
            <Grid container spacing={2}>
              {comparisonData.records.map((record, index) => (
                <Grid item xs={12} key={index}>
                  <Card sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                        Data: {record.data}
                      </Typography>
                      {metrics.map((metric) => (
                        <Box key={metric.key} sx={{ mb: 1 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                            {metric.label}
                          </Typography>
                          <Typography variant="body2">
                            {record[metric.key] !== undefined && record[metric.key] !== ""
                              ? String(record[metric.key])
                              : "Sem informação"}
                          </Typography>
                        </Box>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2">Nenhum registro encontrado para este período.</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
